<template>
  <div class="page-common-body order-info-page-container">
    <div class="order-info-main-body" v-if="order">
      <div class="order-info__title">
        <p>订单详情</p>
        <p>
          <i class="icon icon-contact"></i>
          <span>联系客服</span>
        </p>
      </div>
      <div class="order-info__status">
        <div class="border-title">
          <p>订单号：{{ order.trade_num }}</p>
          <p>
            状态：<span
              :class="{
                complete: Number(order.status) === 6,
                error: Number(order.status) === 11
              }"
              >{{ orderStatusText(Number(order.status)) }}</span
            >
          </p>
        </div>
      </div>
      <div class="order-info__timeline" v-if="Number(order.status) !== 11">
        <order-steps
          class="timeline__container"
          :steps="process"
          :trade-num="order.trade_num"
          :status="orderStatusText(Number(order.status))"
        ></order-steps>
      </div>
      <div class="order-info__main-info">
        <div class="main-info__wrapper">
          <div class="info__box">
            <div class="border-title">
              <p>收件人信息</p>
            </div>
            <div class="box__container">
              <p>收货人：{{ orderAddress.consignee }}</p>
              <p>地址：{{ orderAddress.address }}</p>
              <p>手机：{{ orderAddress.consignee_tel }}</p>
            </div>
          </div>
          <div class="info__box">
            <div class="border-title">
              <p>支付信息</p>
            </div>
            <div class="box__container">
              <template v-if="Number(order.status) === 11">
                <p>{{ orderStatusText(Number(order.status)) }}</p>
              </template>
              <template v-else>
                <p>
                  支付方式：{{
                    Number(order.pay_type) === 1 ? "支付宝" : "微信"
                  }}
                </p>
                <p>运费：￥0.00</p>
                <p>
                  实付金额：{{
                    Number(order.pay_at) === 0
                      ? "待支付"
                      : `￥${order.total_price}`
                  }}
                </p>
              </template>
            </div>
          </div>
          <div class="info__box">
            <div class="border-title">
              <p>物流信息</p>
            </div>
            <div class="box__container">
              <template v-if="Number(order.status) === 11">
                <p>{{ orderStatusText(Number(order.status)) }}</p>
              </template>
              <template v-else>
                <template v-if="order.express_info">
                  <p>物流公司：{{ order.express_info.name }}</p>
                  <p>物流单号：{{ order.express_info.id }}</p>
                  <div class="copy-bt" @click="onCopy(order.express_info.id)">
                    复制物流单号
                  </div>
                </template>
                <template v-else>
                  <p>
                    {{ orderStatusText(Number(order.status)) }}
                  </p>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="order-info__order">
        <div class="border-title">
          <p>订单信息</p>
        </div>
        <template v-if="goods">
          <div
            class="order__box-container"
            v-for="item in goods"
            :key="item.uuid"
          >
            <template v-if="$bowser.mobile">
              <div class="box__mobile-container">
                <div class="box__cover">
                  <img :src="item.goods.cover.normal | parseImage" alt="" />
                </div>
                <div class="box__info-text">
                  <p>{{ item.goods.category.name.cn }}-{{ item.goods.name }}</p>
                  <p>
                    <span v-if="item.extends">{{ item.extends }}</span>
                    <span>数量：{{ item.counts }}</span>
                  </p>
                  <p>
                    <strong>￥ {{ item.price }}</strong>
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="box__unit">
                <div class="unit__product-cover">
                  <img :src="item.goods.cover.normal | parseImage" alt="" />
                </div>
                <div class="unit__product-name">
                  <p>
                    <strong
                      >{{ item.goods.category.name.cn }}-{{
                        item.goods.name
                      }}</strong
                    >
                  </p>
                  <p v-if="item.extends">{{ item.extends }}</p>
                </div>
              </div>
              <div class="box__unit">
                <p>
                  <strong>{{ item.counts }}</strong>
                </p>
              </div>
              <div class="box__unit">
                <p>￥ {{ item.price }}</p>
              </div>
              <div class="box__unit">
                <p>
                  {{ orderStatusText(Number(order.status)) }}
                </p>
              </div>
            </template>
          </div>
        </template>
      </div>
      <div
        class="order-info_checkout-info"
        :class="{
          hasBottom:
            $bowser.mobile && [11, 2].indexOf(Number(order.status)) === -1
        }"
      >
        <div class="info__col">
          <p>商品件数</p>
          <p>{{ goods.length }}件</p>
        </div>
        <div class="info__col">
          <p>金额合计</p>
          <p>{{ order.total_price }}元</p>
        </div>
        <div class="info__col">
          <p>运费</p>
          <p>0.00元</p>
        </div>
        <div class="info__col">
          <p>应付总额</p>
          <p class="big">{{ order.total_price }}元</p>
        </div>
      </div>
    </div>
    <div
      class="mobile-bottom-fixed-container"
      v-if="$bowser.mobile && [11, 2].indexOf(Number(order.status)) === -1"
    >
      <el-button class="mb__bts" v-if="Number(order.status) === 1"
        >支付订单</el-button
      >
      <el-button
        class="mb__bts"
        v-if="[3, 4].indexOf(Number(order.status)) >= 0"
        >确认收货</el-button
      >
    </div>
  </div>
</template>

<script>
import orderSteps from "./_steps";
import { mapMutations } from "vuex";
export default {
  name: "page-order-detail",
  components: {
    orderSteps
  },
  data() {
    return {
      order: null,
      process: null,
      orderAddress: null,
      goods: null
    };
  },
  computed: {
    tradeNo() {
      return this.$route.query.out_trade_no;
    }
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      this.loadOrder();
    },
    loadOrder() {
      const that = this;
      this.PAGE_LOADING();
      that.$request
        .get("/api/user/order", {
          tradeNo: that.tradeNo
        })
        .then(res => {
          const data = res.data;
          that.order = data.order;
          that.process = data.process;
          that.orderAddress = data.address;
          that.goods = data.goods;
          this.PAGE_LOADED();
        });
    },
    onCopy(expressId) {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", expressId);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        alert("链接复制成功！");
      } else {
        alert("复制失败！请手动复制！");
      }
      document.body.removeChild(input);
    }
  }
};
</script>

<style></style>
