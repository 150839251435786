<template>
  <div class="order-status-steps-container">
    <template v-if="$bowser.mobile">
      <div class="order__text-container">
        <p class="order__trade-num order__text">
          <strong>订单号：{{ tradeNum }}</strong>
        </p>
        <p class="order__status order__text">
          状态：<span>{{ status }}</span>
        </p>
      </div>
    </template>
    <div class="order__step-wrapper">
      <div
        class="step__item"
        v-for="(step, index) in wholeProcess"
        :key="index"
        :class="{
          actived: steps.some(item => Number(item.type) === Number(step.val)),
          now: Number(steps[steps.length - 1].type) === Number(step.val),
          finish:
            Number(step.val) === 4 &&
            Number(steps[steps.length - 1].type) === Number(step.val)
        }"
        :style="{
          width: stepWidth
        }"
      >
        <i v-if="$bowser.mobile" class="item__dot"></i>
        <p class="item__name">{{ step.name }}</p>
        <p class="item__time">{{ getTime(step.val) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/filters";
export default {
  name: "c_order-status-step",
  props: {
    steps: {
      type: Array,
      default: null
    },
    tradeNum: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      wholeProcess: [
        {
          name: "提交订单",
          val: 1
        },
        {
          name: "支付订单",
          val: 2
        },
        {
          name: "商品发货",
          val: 3
        },
        {
          name: "确认收货",
          val: 4
        },
        {
          name: "订单完成",
          val: 5
        }
      ],
      active: 1
    };
  },
  computed: {
    stepWidth() {
      return this.wholeProcess ? `${100 / this.wholeProcess.length}%` : 0;
    }
  },
  methods: {
    getTime(val) {
      const data = this.steps.find(item => Number(item.type) === Number(val));

      return data ? parseTime(data.created_at, "{y}-{m}-{d} {h}:{i}:{s}") : "";
    }
  }
};
</script>

<style></style>
